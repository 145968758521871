const ProjectNames = {
  Petronus: "petronus",
  AppLogger: "app_logger",
  SpellABee: "spell_a_bee",
  VrHangouts: "vr_hangouts",
  Canary: "canary",
  Moxie: "moxie",
  Bridge: "bridge",
  Knob: "knob",
};

export default ProjectNames;
